<form [formGroup]="orderHistoryForm">
  <div class="form-row pl-3 pb-3 mx-0 align-items-end">
    <div class="form-group col-md-4 so-po-number-container">
      <label for="Search" class="form-label">{{"orderHistoryPage.search" |cxTranslate}}</label>
      <p-autoComplete formControlName="search" styleClass="orderHistory-autocomplete"
        placeholder="{{'orderHistoryPage.enterSgreSo'|cxTranslate}}" [suggestions]="searchSuggestions"
        (completeMethod)="filterSearch($event)" [minLength]="3" #searchValue></p-autoComplete>
    </div>
    <div class="form-group col-6 col-md-2 custom-calendar from-field">
      <label for="DateFrom" class="form-label">{{"orderHistoryPage.dateFrom" |cxTranslate}}</label>
      <p-calendar formControlName="dateFrom" [maxDate]="maxDate" (onSelect)="updateDates()" [dateFormat]="dateFormat" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" [showButtonBar]="true"/>
    </div>
    <div class="d-none align-self-end mb-3">
      <div class="dash mx-lg-2"></div>
    </div>
    <div class="form-group col-6 col-md-2 custom-calendar to-field">
      <label for="DateTo" class="form-label">{{"orderHistoryPage.dateTo" |cxTranslate}}</label>
      <p-calendar formControlName="dateTo" [minDate]="minDate" (onSelect)="updateDates()" [dateFormat]="dateFormat" [iconDisplay]="'input'" [showIcon]="true" inputId="buttondisplay" [showButtonBar]="true"/>
    </div>
    <div class="form-group col-6 col-md-2 pt-1">
      <button type="submit" class="btn cstm-btn-primary" style="height: 46px;"
        (click)="filterData(searchValue.value)">{{"orderHistoryPage.search" |cxTranslate}}</button>
    </div>
  </div>
</form>
